import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Review Request Flow" next={{
  name: 'Review Request Templates',
  path: '/review-request-templates'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "request-sequence"
    }}>{`Request Sequence`}</h2>
    <p>{`This flow gets right to the point, which reduces friction and increases the chances of getting a review.`}</p>
    <p>{`As depicted in the diagram below customers are sent an initial review request.`}</p>
    <p>{`If they post a review that is the end of the flow.`}</p>
    <p>{`If they don’t post a review after the initial request they are sent a follow up review request a few days later.`}</p>
    <p>{`If the second request is unsuccessful a final request is sent a few days later.`}</p>
    <p>{`The customer will be contacted, at most, three times.`}</p>
    <img src="/help-center/direct-review-request-flow.png" />
    <h2 {...{
      "id": "customer-opt-out"
    }}>{`Customer Opt-out`}</h2>
    <p>{`At any point in the flow, regardless of the contact method, the customer can use the unsubscribe link to stop receiving any further requests.`}</p>
    {
      /* diagrams: https://app.diagrams.net/#G1SsgVNe3MWnnoB5NvCjii35kj8vy0545a */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      